.Filters {
  margin-top: 10px;
  .filter-name {
    font-weight: 500;
    padding: 10px 0 5px;
    color: #999;
    font-size: 80%;
  }
  .categories {
    .MuiFormControlLabel-label {
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
  }
  .categories-scroll {
    max-height: calc(100vh - 436px);
    min-height: 100px;
    overflow-y: scroll;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px 10px 10px;
  }
  .category-name {
    margin-right: 7px;
  }
  .category > .MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiFormControlLabel-label {
    font-size: 0.95rem;
  }
  .subcategories {
    padding-left: 30px;
  }
  .autocompletes {
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
    & > .MuiAutocomplete-root + .MuiAutocomplete-root {
      margin-top: 15px;
    }
  }
  .MuiAccordionSummary-root {
    min-height: 40px;
    &.Mui-expanded {
      min-height: 44px;
    }
    .MuiAccordionSummary-content {
      margin: 8px 0;
    }
    .MuiIconButton-root {
      padding: 8px;
    }
  }
}
