.CategoryPage {
  .TitleBar {
    margin: 0;
    padding: 18px 0;
  }
  .CategoryDetail > .fade-bar {
    position: fixed;
    height: 70px;
  }
  .Subcategory {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 20px;
    & > .header {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  .Cards:not(.mosaic) {
    grid-template-columns: repeat(auto-fill, 280px);
  }
}