.IndicatorPage {
  a {
    display: inline-flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
  a.small {
    margin-left: 20px;
    font-size: 90%;
    svg {
      margin-left: 5px;
    }
  }
  .Cards {
    margin-top: 30px;
    .header {
      font-size: 18px;
    }
  }
}