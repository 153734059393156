.Cards {
  position: relative;
  &:not(.mosaic) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    align-items: flex-start;
    gap: 20px;
  }
  &.mosaic > .MuiCard-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
  }
  .MuiCardContent-root {
    padding: 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .header {
    display: flex;
    font-size: 20px;
    font-weight: 300;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
    svg {
      margin-right: 10px;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 16px 16px 40px;
    a + a {
      margin-top: 10px;
    }
    a {
      text-decoration: none;
      &:hover, &:active {
        text-decoration: underline;
      }
      span {
        order: 1;
      }
      svg {
        order: 0;
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}