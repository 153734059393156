.Bubble {
  font-size: 13px;
  margin-bottom: 20px;
  color: #777;
  padding: 8px;
  border: 1px solid #dadae5;
  border-radius: 7px;
  position: relative;
  background-color: #fafaff;
  svg {
    position: absolute;
    background: #fff;
    top: -8px;
    left: 0;
    transform: translateX(-7px);
  }
}