.ReportPage {
  h2 {
    margin: 0;
    padding: 20px 0;
  }
}

.ReportDetail {
  a {
    display: flex;
    align-items: center;
    svg {
      margin-left: 7px;
    }
  }
  .source-links {
    display: flex;
    align-items: flex-start;
    a {
      max-width: 600px;
    }
    a + a {
      margin-left: 20px;
      font-size: 90%;
    }
  }
  .Cards {
    margin-top: 30px;
    .header {
      font-size: 18px;
    }
  }
}
