.SearchResultsWrapper {
  flex: 1 1 80%;
  position: relative;
  height: 100vh;
  .fade-bar {
    height: 50px;
  }
}

.SearchResults {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 20px 20px 20px 0;
  &:not(.all) > * + * {
    margin-top: 15px;
  }
  & > .header {
    font-size: 19px;
    font-weight: 300;
    padding: 10px 0;
  }
  &.all {
    .SearchResult:hover,
    .SearchResult:active {
      border-color: #f4f4f4;
    }
  }
  .group {
    .items {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;
      & > * + * {
        margin-top: 15px;
      }
    }
  }
  .cname {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #888;
    padding: 15px 0 10px;
    margin-bottom: 10px;
    background-color: #fdfdfd;
    position: sticky;
    top: -20px;
  }
  .subgroup {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    .sname {
      font-size: 18px;
      font-weight: 300;
      padding: 5px 0 0;
    }
    .items {
      padding: 20px 0 10px;
    }
    .SearchResult {
      text-decoration: underline;
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
    }
    .SearchResult + .SearchResult {
      margin-top: 10px;
    }
  }
  .subgroup + .subgroup {
    margin-top: 20px;
  }
}

.SearchResult {
  color: var(--secondary);
  text-decoration: none;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  padding: 15px;
  background: #fff;
  font-size: 18px;
  font-weight: 300;
  &:hover {
    border-color: rgba(241,101,37,0.4);
  }
  &:active {
    border-color: rgba(241,101,37,0.8);
  }
  .MuiChip-root {
    max-width: 100%;
  }
}

.CombinedListItem {
  font-size: 15px;
  font-weight: 400;
  .header {
    font-size: 18px;
    font-weight: 300;
    padding: 5px 0 30px 0;
  }
  .icon-label {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .Fields .labelvalue {
    grid-template-columns: 150px 1fr;
  }
}