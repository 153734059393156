.Menu {
  position: fixed !important;
  top: 10px;
  right: 20px;
}

.PasteLinkModal {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .MuiButton-root {
    margin-top: 10px;
    padding: 4px ​16px;
  }
  .MuiFormControl-root {
    @media screen and (min-width: 900px) {
      width: 500px;
    }
  }
}